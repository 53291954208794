import React from "react";

import { Footer } from "../Footer/Footer";
import { Decorates } from "../Decorates/Decorates";

import { StyledDocumentWrapper } from "./style";

import { StyledBorder, StyledPageWrapper, StyledDecoratesWrapper, StyledHeaderWrapper } from "../IndexPageComponent/style";

const firstGameStyle = {
    position: "absolute",
    top: "-347px",
    left: "-511px",
    opacity: "0.4",
    transform: "rotate(-62deg) rotateY(180deg)"
};

const secondGameStyle = {
    width: "795px",
    position: "absolute",
    top: "-50px",
    left: "1060px",
    opacity: ".4",
    transform: "rotateY(180deg) rotate(-310deg)"
};

const spinningStyle = {
    position: "absolute",
    top: "10px",
    left: "830px"
};

const owlStyle = {
    position: "absolute",
    bottom: "340px",
    left: "20px",
    opacity: ".4"
};

export const DocumentWrapper = ({ children, header }) => {
    return (
        <StyledPageWrapper>
            <StyledHeaderWrapper>{header}</StyledHeaderWrapper>
            <StyledBorder>
                <StyledDecoratesWrapper>
                    <Decorates firstGameStyle={firstGameStyle} secondGameStyle={secondGameStyle} spinningStyle={spinningStyle} owlStyle={owlStyle} />
                    <StyledDocumentWrapper isTermOfUse>{children}</StyledDocumentWrapper>
                    <Footer />
                </StyledDecoratesWrapper>
            </StyledBorder>
        </StyledPageWrapper>
    );
};
