import styled from "styled-components";
import { titleColor } from "../../styles/variables";
import { StyledTitle, StyledText } from "../IndexPageComponent/style";

export const StyledDocumentWrapper = styled.div`
    padding: 0 10px;
    margin: 50px 140px;
    max-height: 845px;
    overflow-y: scroll;
    text-align: justify;
    color: ${titleColor};

    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: $shadow;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #b9e91c;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #87dd16;
    }

    ol {
        list-style-type: upper-alpha; /* Заглавные буквы */
    }

    a {
        color: ${titleColor};
    }

    ${({ isTermOfUse }) =>
        isTermOfUse &&
        `
        ul {
            font-weight: 400;
            font-family: Acme;
            text-align: justify;
            line-height: 1.3em;
            letter-spacing: .05em;
            font-size: 21px;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;

            li {
                flex: 1 1 100%;
            }

            ul {
                margin-left: 10px;

                ul {
                    margin-left: 10px;
                }
            }
        }
    `}
`;

export const StyledDocumentWrapperTitle = styled(StyledTitle)`
    padding: 30px;

    & > h2 {
        font-family: Acme;
        font-weight: 700;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        font-size: 21px;
        text-align: center;
        text-transform: none;
    }
`;

export const StyledDocumentWrapperText = styled(StyledText)`
    text-align: justify;

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-left: 10px;

            & > .text {
                text-align: justify;
            }
        }
    }
`;
